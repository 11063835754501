import request from '@/utils/request'


// 获取agentIdentify
export function getAgentIdentify() {
  return request({
    url: '/identify',
    params: { domain: process.env.NODE_ENV === 'development' ? 'vcc3test.lc-vip888.top' : location.hostname.replace('www.','') },
    method: 'get'
  })
}
// 获取配置信息
export function getConfig() {
  return request({
    url: '/configs',
    method: 'get'
  })
}
/**
 *
首页相关
 *
 */

export function getCarousels() {
  return request({
    url: '/app/carousels',
    method: 'get'
  })
}
export function getIntro() {
  return request({
    url: '/app/info',
    method: 'get'
  })
}
export function getWealthCate() {
  return request({
    url: '/wealth/category',
    method: 'get'
  })
}
export function getIssue() {
  return request({
    url: '/app/helpers',
    method: 'get'
  })
}
export function getProductsByCate(id) {
  return request({
    url: `/wealth/${id}/products`,
    method: 'get',
    params: {pageNo:1,pageSize:20}
  })
}
export function getCountries(id) {
  return request({
    url: `/countries`,
    method: 'get'
  })
}
// 获取所有币种
export function getCoins() {
  return request({
    url: `/app/coins`,
    method: 'get'
  })
}
/**
 *
  首页相关end
 *
 */


/**
 *
登录相关
 *
 */

export function login(data) {
  return request({
    url: `/login`,
    method: 'post',
    data
  })
}
export function refreshToken() {
  return request({
    url: `/refreshToken`,
    method: 'get',
  })
}
// 发送手机验证码（未登录）
export function getMobileCodeWithoutToken(data) {
  return request({
    url: `/verifyCode/mobile`,
    method: 'post',
    data
  })
}
// 发送邮箱验证码（未登录）
export function getEmailCodeWithoutToken(data) {
  return request({
    url: `/verifyCode/mail`,
    method: 'post',
    data
  })
}
export function register(data) {
  return request({
    url: `/members`,
    method: 'post',
    data
  })
}
// 重置密码
export function resetPassword(data) {
  return request({
    url: `/passwd/resetByMobile`,
    method: 'put',
    data
  })
}

/**
 * 个人中心
 */
export function getUserInfo() {
  return request({
    url: `/me/info`,
    method: 'get'
  })
}
export function editUserInfo(data) {
  return request({
    url: `/me/info`,
    method: 'put',
    data
  })
}
// 修改登录密码
export function updateLoginPwd(data) {
  return request({
    url: `/me/loginPasswd`,
    method: 'put',
    data
  })
}
// 修改资金密码
export function updateFundsPwd(data) {
  return request({
    url: `/me/fundsPasswd`,
    method: 'put',
    data
  })
}
// 提交实名信息
export function authenticate(data) {
  return request({
    url: `/me/verify`,
    method: 'post',
    data
  })
}
// 获取实名认证状态
export function getAuthInfo() {
  return request({
    url: `/me/verify`,
    method: 'get',
  })
}
// 修改个人计价单位
export function updateValuation(data) {
  return request({
    url: `/me/valuation`,
    method: 'put',
    data
  })
}
// 添加提现地址
export function addWallet(data) {
  return request({
    url: `/me/wallets`,
    method: 'post',
    data
  })
}
// 获取提现地址
export function getWallets() {
  return request({
    url: `/me/wallets`,
    method: 'get'
  })
}
// 删除提现地址
export function deleteWallet(id) {
  return request({
    url: `/me/wallets/${id}`,
    method: 'delete'
  })
}
// 添加银行卡
export function addBankCard(data) {
  return request({
    url: `/me/banks`,
    method: 'post',
    data
  })
}
// 获取银行卡
export function getBankCards() {
  return request({
    url: `/me/banks`,
    method: 'get'
  })
}
// 删除银行卡
export function deleteBankCard(id) {
  return request({
    url: `/me/banks/${id}`,
    method: 'delete'
  })
}
// 绑定邮箱
export function bindEmail(data) {
  return request({
    url: `/me/email`,
    method: 'put',
    data
  })
}
// 绑定手机
export function bindMobile(data) {
  return request({
    url: `/me/mobile`,
    method: 'put',
    data
  })
}

/**
 * 功能相关
*/
// 获取法币币种
export function getCurrencies() {
  return request({
    url: `/app/currency`,
    method: 'get'
  })
}
// 获取验证码
export function getCodeByToken(data) {
  return request({
    url: `/verifyCode`,
    method: 'post',
    data
  })
}
/**
 * 资产中心
*/
export function getAccountNames() {
  return request({
    url: `/funds/accounts`,
    method: 'get',
  })
}
// 获取充值渠道
export function getDepositChannels(params) {
  return request({
    url: `/funds/deposit/channels`,
    method: 'get',
    params
  })
}
// 提交充值
export function deposit(data) {
  return request({
    url: `/funds/deposit`,
    method: 'post',
    data
  })
}
// 提交出款
export function withdraw(data) {
  return request({
    url: `/funds/withdraw`,
    method: 'post',
    data
  })
}
// 划转
export function transfer(data) {
  return request({
    url: `/funds/transfer`,
    method: 'post',
    data
  })
}
// 获取充值历史
export function getDepositHistory(params) {
  return request({
    url: `/funds/deposit`,
    method: 'get',
    params
  })
}
// 获取提现历史
export function getWithdrawHistory(params) {
  return request({
    url: `/funds/withdraw`,
    method: 'get',
    params
  })
}
// 获取划转历史
export function getTransferHistory(params) {
  return request({
    url: `/funds/transfer`,
    method: 'get',
    params
  })
}
// 账变记录
export function getChanges(params) {
  return request({
    url: `/funds/bills`,
    method: 'get',
    params
  })
}
// 获取资产各账户信息
export function getUserAssets(params) {
  return request({
    url: `/funds/info`,
    method: 'get',
    params
  })
}
// 获取单账户、单币种余额
export function getUserAssetsByCoin(params) {
  return request({
    url: `/funds/detail`,
    method: 'get',
    params
  })
}
// 法幣-出售
export function getC2cRates() {
  return request({
    url: `/c2c/rates`,
    method: 'get'
  })
}
// 法幣-出售-訂單記錄
export function getC2cWithdrawHistory(params) {
  return request({
    url: '/c2c/history/withdraw',
    method: 'get',
    params
  })
}
// 法幣-購買-訂單記錄
export function getC2cDepositHistory(params) {
  return request({
    url: '/c2c/history/deposit',
    method: 'get',
    params
  })
}
// 法幣-出售
export function sentC2cDraw(data) {
  return request({
    url: '/c2c/draw',
    method: 'post',
    data
  })
}

/**
 * 现货相关
*/
export function getSpotList() {
  return request({
    url: `/spot/products`,
    method: 'get',
  })
}
// 购买现货
export function buySpot(data) {
  return request({
    url: `/spot/product/buy`,
    method: 'post',
    data
  })
}
// 出售现货
export function sellSpot(data) {
  return request({
    url: `/spot/product/sell`,
    method: 'post',
    data
  })
}
// 订单历史
export function getSpotRecords(params) {
  return request({
    url: `/spot/history`,
    method: 'get',
    params
  })
}
/**
 * 期货相关
*/
export function getFutureList() {
  return request({
    url: `/future/products`,
    method: 'get',
  })
}
// 合约列表
export function getContractList(id) {
  return request({
    url: `/future/contracts/${id}`,
    method: 'get',
  })
}
// 购买期货
export function buyFuture(data) {
  return request({
    url: `/future/product`,
    method: 'post',
    data
  })
}
// 期货订单历史
export function getFutureHistory(params) {
  return request({
    url: `/future/history`,
    method: 'get',
    params
  })
}
// 期货订单详情
export function getFutureOrderDetail(orderId) {
  return request({
    url: `/future/${orderId}`,
    method: 'get'
  })
}
// 期货订单待交割
export function getFutureRecords(params) {
  return request({
    url: `/future/records`,
    method: 'get',
    params
  })
}
// 获取订单列表
export function getOrder(id,status,queryData) {
    return request({
      url: `/wealth/${id}/records`,
      method: 'get',
      params: {lastId: queryData.lastId, status, pageSize:queryData.pageSize}
    })
  }
// 理財-申请开通
export function financialApply() {
    return request({
      url: `/wealth/apply`,
      method: 'post'
    })
  }
// 理財-发送手机验证码
export function financialMobileCode() {
    return request({
      url: `/verifyCode`,
      method: 'post',
      data:{
        scene: "REDEEM_PLEDGE",
        type: "MOBILE"
      }
    })
}
// 理財-获取指定分类托管中订单总
export function financialDetail(id) {
    return request({
      url: `/wealth/${id}/detail`,
      method: 'get'
    })
  }
// 理財-购买理财产品
export function financialBuyProducts(id,data) {
    return request({
      url: `/wealth/products/${id}`,
      method: 'post',
      data
    })
  }
// 获取理财产品详情
export function getProductDetail(id) {
	return request({
		url: `/wealth/products/${id}`,
		method: 'get',
	})
}

// 理財-贖回訂單
export function financialRecords(id,code) {
    return request({
      url: `/wealth/records/${id}`,
      method: 'put',
      data: { code }
    })
  }
